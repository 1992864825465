export const reviews = [
    {
        "_id": "66299d0f3f100f3b8031072e",
        "author_name": "Jakub M",
        "author_url": "https://www.google.com/maps/contrib/111131930146233633006/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocLYHVhBD3d29dieewXF6Wm0SPRTKBqUqXSyxsy1Hg-oPFZHvg=s128-c0x00000000-cc-rp-mo",
        "rating": 5,
        "relative_time_description": "w ostatnim tygodniu",
        "text": "Obsługa jak i materiały na wysokim poziomie. Wszystko załatwione zgodnie z ustaleniami. Polecam :)",
        "time": 1713936349,
        "translated": false
    },
    {
        "_id": "66299d0f3f100f3b8031072f",
        "author_name": "Sylwia Kaczmarek",
        "author_url": "https://www.google.com/maps/contrib/117571149068311240440/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocKXxt8dxMrvgVq-PCBVxgmfscgbYAJosBQffO02tQjuFaV0lg=s128-c0x00000000-cc-rp-mo",
        "rating": 5,
        "relative_time_description": "w ostatnim tygodniu",
        "text": "Polecam 🙃",
        "time": 1713708826,
        "translated": false
    },
    {
        "_id": "66299d0f3f100f3b80310730",
        "author_name": "lena vp",
        "author_url": "https://www.google.com/maps/contrib/100537620913737647981/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocJFNGY7E4C5iZZVxokUrIF2NvzhXtF7x41ZJwFainKf-EYrKg=s128-c0x00000000-cc-rp-mo",
        "rating": 5,
        "relative_time_description": "tydzień temu",
        "text": "Kupiłem podmuròwki.  Za złożenie zamówienia odpowiedzialna była bardzo miła Pani, wysłuchała nas, zaproponowała najlepsze rozwiązanie i uwzględniła wszystkie nasze życzenia.  Terminowa dostawa, staranny rozładunek, dobra jakość podmuròwköw, zwrot pieniędzy za palety - wszystko bez problemów, wszystko Super.  Polecam!",
        "time": 1713377050,
        "translated": false
    },
    {
        "_id": "66299d0f3f100f3b80310731",
        "author_name": "Marcin Lew",
        "author_url": "https://www.google.com/maps/contrib/113801455399331173183/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjU1wF76CtpEnjoP7meTchHGtW78xqNm410DmQJl-XlHOPGGRwLC=s128-c0x00000000-cc-rp-mo-ba2",
        "rating": 5,
        "relative_time_description": "tydzień temu",
        "text": "Bardzo sympatyczna współpraca, małe zamówienie, a mimo to wszystko wyjaśnione szczegółowo, pełna profeska",
        "time": 1713340191,
        "translated": false
    },
    {
        "_id": "66299d0f3f100f3b80310732",
        "author_name": "B. Ś.",
        "author_url": "https://www.google.com/maps/contrib/113860558727128545513/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocJC-hC4HcJ3R6RTw1Gkot3tEITABDuYIRQUnvhINtAAtRfCNw=s128-c0x00000000-cc-rp-mo-ba3",
        "rating": 5,
        "relative_time_description": "tydzień temu",
        "text": "Bardzo solidna firma,solidny płot.Dorzucaja od siebie kilka płyt na wypadek gdyby któraś była uszkodzona.W naszym przypadku nie bylo uszkodzeń.Dostawa terminowa.A no i należy wspomnieć że mają duży wybór wzorów więc jest w czym wybierać.Calym sercem polecam!",
        "time": 1713257985,
        "translated": false
    },
    {
        "_id": "66299d103f100f3b803108eb",
        "author_name": "B. Ś.",
        "author_url": "https://www.google.com/maps/contrib/113860558727128545513/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocJC-hC4HcJ3R6RTw1Gkot3tEITABDuYIRQUnvhINtAAtRfCNw=s128-c0x00000000-cc-rp-mo-ba3",
        "rating": 5,
        "relative_time_description": "tydzień temu",
        "text": "Bardzo solidna firma,solidny płot.Dorzucaja od siebie kilka płyt na wypadek gdyby któraś była uszkodzona.W naszym przypadku nie bylo uszkodzeń.Dostawa terminowa.A no i należy wspomnieć że mają duży wybór wzorów więc jest w czym wybierać.Calym sercem polecam!",
        "time": 1713257985,
        "translated": false
    },
    {
        "_id": "66299d103f100f3b803108ec",
        "author_name": "lena vp",
        "author_url": "https://www.google.com/maps/contrib/100537620913737647981/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocJFNGY7E4C5iZZVxokUrIF2NvzhXtF7x41ZJwFainKf-EYrKg=s128-c0x00000000-cc-rp-mo",
        "rating": 5,
        "relative_time_description": "tydzień temu",
        "text": "Kupiłem podmuròwki.  Za złożenie zamówienia odpowiedzialna była bardzo miła Pani, wysłuchała nas, zaproponowała najlepsze rozwiązanie i uwzględniła wszystkie nasze życzenia.  Terminowa dostawa, staranny rozładunek, dobra jakość podmuròwköw, zwrot pieniędzy za palety - wszystko bez problemów, wszystko Super.  Polecam!",
        "time": 1713377050,
        "translated": false
    },
    {
        "_id": "66299d103f100f3b803108ed",
        "author_name": "Dimmson Two",
        "author_url": "https://www.google.com/maps/contrib/105157938455122625760/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocIGNLyhg69Fu7_3Ydllyah0w4y2BfEalaGy-BT54qPVs6mRslI=s128-c0x00000000-cc-rp-mo-ba4",
        "rating": 5,
        "relative_time_description": "5 miesięcy temu",
        "text": "Jakość jest doskonała, modele są oryginalne, szybka instalacja, dziękuję! Polecam!",
        "time": 1700587965,
        "translated": false
    },
    {
        "_id": "66299d103f100f3b803108ee",
        "author_name": "Marcin Lew",
        "author_url": "https://www.google.com/maps/contrib/113801455399331173183/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjU1wF76CtpEnjoP7meTchHGtW78xqNm410DmQJl-XlHOPGGRwLC=s128-c0x00000000-cc-rp-mo-ba2",
        "rating": 5,
        "relative_time_description": "tydzień temu",
        "text": "Bardzo sympatyczna współpraca, małe zamówienie, a mimo to wszystko wyjaśnione szczegółowo, pełna profeska",
        "time": 1713340191,
        "translated": false
    },
    {
        "_id": "66299d103f100f3b803108ef",
        "author_name": "Daniel S",
        "author_url": "https://www.google.com/maps/contrib/117721068397696691121/reviews",
        "language": "pl",
        "original_language": "pl",
        "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjWmv7xC4P9mdAfDiF0S0DvcfoGjlJBhW7x9lc_vldgR2UFYsfs=s128-c0x00000000-cc-rp-mo-ba2",
        "rating": 5,
        "relative_time_description": "5 miesięcy temu",
        "text": "Praca z tą firma to czysta przyjemność. Szybka wycena, szybka dostawa i szybki i profesjonalny montaż! Dwa razy korzystałem z usług firmy i nigdy się nie zawiodłem. Mam jeszcze kilka elementów do wykonania - więc Panowie do zobaczenia!",
        "time": 1700549552,
        "translated": false
    }
];